import { useId } from 'react';

const ArrowLeft = () => {
  const fillId = useId();

  return (
    <svg
      width="1440"
      height="647"
      viewBox="0 0 1440 647"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: 0.5 }}
    >
      <path
        d="M2180 361.111L1891.1 650L288.896 650L4.07312e-05 361.111L4.07986e-05 288.889L288.896 0.000281288L1891.1 7.61443e-07L2180 288.889L2180 361.111Z"
        fill={`url(#${fillId})`}
      />

      <defs>
        <radialGradient
          id={fillId}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1200 -2000) rotate(57.8472) scale(3396.72 3972.41)"
        >
          <stop offset="0.0249024" stopColor="#54B393" />
          <stop offset="0.7" stopColor="#54B393" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default ArrowLeft;
