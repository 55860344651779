import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { dialogMap } from '@dialogs';

import interactionHooks from '@util/interactions';

import BaseCallToActionCard from '@components/BaseCallToActionCard';
import Button from '@components/Button';
import { switchPagesKey } from '@constants/personalization';

const ActionsWithInteractionHook = ({
  actions = [],
  size = 'medium',
  isPersonalised = false,
}) => {
  const buttonSize = size === 'small' ? 'medium' : 'large';
  return (
    <>
      {actions.map((action, i) => {
        const interaction = action.interaction
          ? interactionHooks[action.interaction]()
          : null;

        const Dialog = action.dialog ? dialogMap[action.dialog] : null;

        return Dialog ? (
          <Dialog key={i}>
            <Button
              key={i}
              size={buttonSize}
              withArrow={action.withArrow}
              variant={action.type}
              colorScheme="text"
              trackingScope={
                isPersonalised
                  ? 'Personalised CallToActionCard'
                  : 'CallToActionCard'
              }
              isStartFreeTrial={action.isStartFreeTrial ?? null}
            >
              {isPersonalised && action.personalisedLabel
                ? action.personalisedLabel
                : action.label}
            </Button>
          </Dialog>
        ) : (
          <Button
            key={i}
            size={buttonSize}
            withArrow={action.withArrow}
            variant={action.type}
            colorScheme="text"
            href={action.href}
            onClick={interaction}
            trackingScope={
              isPersonalised
                ? 'Personalised CallToActionCard'
                : 'CallToActionCard'
            }
            isStartFreeTrial={action.isStartFreeTrial ?? null}
          >
            {isPersonalised && action.personalisedLabel
              ? action.personalisedLabel
              : action.label}
          </Button>
        );
      })}
    </>
  );
};

export const CallToActionCard = ({
  actions,
  claim,
  subline,
  illustration = 'Simple',
  theme = 'light',
  gradient,
  overlay,
  image,
  flipGradient = false,
  bleed = false,
  align = 'left',
  verticalAlign = 'bottom',
  size = 'medium',
  listItems,
  eyebrow,
  personalisedClaim,
  personalisedSubline,
}) => {
  const [switchPagesValue] = useLocalStorage(switchPagesKey);
  const [isPersonalised, setIsPersonalised] = useState(false);

  useEffect(() => setIsPersonalised(!!switchPagesValue), []);

  return (
    <BaseCallToActionCard
      actions={
        <ActionsWithInteractionHook
          actions={actions}
          size={size}
          isPersonalised={isPersonalised}
        />
      }
      claim={isPersonalised && personalisedClaim ? personalisedClaim : claim}
      subline={
        isPersonalised && personalisedSubline ? personalisedSubline : subline
      }
      illustration={illustration}
      theme={theme}
      gradient={gradient}
      overlay={overlay}
      flipGradient={flipGradient}
      bleed={bleed}
      align={align}
      verticalAlign={verticalAlign}
      listItems={listItems}
      eyebrow={eyebrow}
      image={image}
      size={size}
    />
  );
};
