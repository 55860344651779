import { useId } from 'react';

const ArrowRight = () => {
  const fillId = useId();

  return (
    <svg
      width="1440"
      height="647"
      viewBox="0 0 1440 647"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: 0.5 }}
    >
      <path
        d="M1151.1 647L-0.00136126 647L-0.00139154 -2.99952L1151.1 -2.99955L1440 285.889L1440 358.111L1151.1 647Z"
        fill={`url(#${fillId})`}
      />

      <defs>
        <radialGradient
          id={fillId}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(800 -1700) rotate(112) scale(3113 2862)"
        >
          <stop
            offset="0.0249024"
            stopColor="var(--page-section-gradient-color)"
          />
          <stop
            offset="0.7"
            stopColor="var(--page-section-gradient-color)"
            stopOpacity="0"
          />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default ArrowRight;
